import React from 'react';
import { graphql } from 'gatsby';
import './BlogPost.scss';
import SanitizedHTML from 'react-sanitized-html';
import { Link } from 'gatsby';

export default function BlogPost({ data, location }) {
  const post = data.allWordpressPost.nodes[0];
  const featuredMediaUrl = post.featured_media?.source_url;
  const featuredMediaAltText = post.featured_media?.alt_text;
  const postCreationDate = Date.parse(post.date);
  return (
    <div className="container">
      <div className="row">
        <div className="col-12 blog-post-title">
          <h1>
            <SanitizedHTML html={post.title} />
          </h1>
        </div>
        <div className="col-6">
          <div>Posted {new Intl.DateTimeFormat('en-GB').format(postCreationDate)}</div>
        </div>
        <div className="col-6 blog-post-share">
          <p>share: fb, insta</p>
        </div>
        <div className="col-12 blog-post-image">
          {featuredMediaUrl ? <img src={featuredMediaUrl} alt={featuredMediaAltText} /> :null}
        </div>
        <div className="col-12 blog-post-description">
          <SanitizedHTML html={post.content} />
        </div>
        <div className="col-12 blog-post-back">
          <Link to={location.state && location.state.prevPath ? location.state.prevPath : '/blog/'}>
            <div className="btn btn-secondary">← Return to all posts</div>
          </Link>
        </div>
      </div>
    </div>
  );
}
export const query = graphql`
query ($slug: String!) {
  allWordpressPost(filter: {slug: {eq: $slug}}) {
    nodes {
      title
      content
      date
      featured_media {
        alt_text
        source_url
      }
    }
  }
}
`;
